/**
 * Copyright 2023 SweetCode. All rights reserved.
 *
 * Process lazy loaded PMW scripts
 */

// Preload the scripts
document.querySelectorAll("script[type=\"text/pmw-lazy\"]").forEach((script) => {
	let preloadLink  = document.createElement("link")
	preloadLink.href = script.src
	preloadLink.rel  = "preload"
	preloadLink.as   = "script"
	document.head.appendChild(preloadLink)
})

// All user interaction events to listen to
const eventsToListenTo = [
	"keydown",
	"mousedown",
	"mousemove",
	"touchmove",
	"touchstart",
	"touchend",
	"wheel",
]

// Load scripts after interaction
function activateLazyLoadedScripts() {

	document.querySelectorAll("script[type=\"text/pmw-lazy\"]").forEach((script) => {

		script.remove()
		let preloadedScript = document.createElement("script")
		preloadedScript.src = script.src
		document.body.appendChild(preloadedScript)
	})
}

// Add event listeners to any interaction events
eventsToListenTo.forEach((event) => {
	document.addEventListener(event, function handler() {

		// console log timestamp
		// console.log("timestamp: " + Date.now())

		activateLazyLoadedScripts()

		// detach event listener
		document.removeEventListener(event, handler)

		// detach all event listeners
		// eventsToListenTo.forEach(function (event) {
		// 	document.removeEventListener(event, handler)
		// })
	})
})

// Always fully load scripts on cart and checkout pages
document.addEventListener("DOMContentLoaded", () => {

	if (typeof wpmDataLayer === "undefined") {

		// At this point the wpmDataLayer should be defined
		// It might not be the case if a third-party plugin blocks it, such as a consent management platform or a JavaScript optimizer.
		// console.error("Pixel Manager error: The wpmDataLayer is undefined. Whitelist the wpmDataLayer from any consent management platforms and JavaScript optimizers.")
		return
	}

	if (
		wpmDataLayer?.shop?.page_type === "cart"
		|| wpmDataLayer?.shop?.page_type === "checkout"
	) {
		activateLazyLoadedScripts()
	}
})
